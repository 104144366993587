// QRScanner.js
import React, {useState, useEffect} from 'react';
import QrReader from 'react-qr-reader';
import '../QrStyles.css'; // Importa el archivo CSS

const QRScanner = () => {
  const [scanResult, setScanResult] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Solicitar permiso para mostrar notificaciones al cargar el componente
    if ('Notification' in window && Notification.permission !== 'granted') {
      Notification.requestPermission();
    }
  }, []);

  const handleScan = (data) => {
    if (data) {
      setScanResult(data);
      reportToBackend(data);
    }
  };

  const handleError = (err) => {
    console.error(err);
    setError(err);
  };

  const reportToBackend = async (data) => {
    try {
      const response = await fetch(`/api/v1/app/read/${data}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      });
      const result = await response.json();
      alert(`${result.message || result.error}`);
    } catch (error) {
      console.error('Error reporting to backend:', error);
    }
  };

  const showNotification = (title, body) => {
    if (Notification.permission === 'granted') {
      new Notification(title, {body});
    }
  };

  return (
    <div style={styles.container}>
      <h1>Apunta la cámara a un código QR para escanearlo</h1>
      {error && <p>Error: {error.message}</p>}
      <div className="qr-reader-container">
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={styles.qrReader}
        />
      </div>
      {scanResult && (
        <div>
          <p>QR Code: {scanResult}</p>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center',
  },
  qrReader: {
    width: '100%',
    height: '100%',
  },
};

export default QRScanner;
