// App.js
import React from 'react';
import QRScanner from './view/qrScaner';

function App() {
  return (
    <div className="App">
      <main>
        <QRScanner/>
      </main>
    </div>
  );
}

export default App;
